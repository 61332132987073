<template>
<ul class="text-center">
  <li class="flex flex-col ">
     <h1 class="pb-2">NR.1</h1>
    <a href="https://tympanus.net/Development/PageFlipLayout/" class="hover:text-red-600">Demo</a>
    <a href="https://github.com/codrops/PageFlipLayout" class="hover:text-red-600">Source</a>
  </li>
  <li class="flex flex-col">
    <h1 class="py-2">NR.2</h1>

    <router-link to="/gsapexample1" class=" hover:text-red-600">Example Nr.2</router-link>
  </li>


  <li class="flex flex-col">
    <h1 class="py-2">NR.3</h1>

    <router-link to="/gsapexample3" class=" hover:text-red-600">Example Nr.3</router-link>
  </li>

</ul>
</template>

<script>


export default {
  name: "GSAPExamples",

  components: {



  }
}
</script>

<style scoped>

</style>